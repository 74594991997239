import React from "react";
import DialogComponent from "../global/dialog";
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import {
  FORM_FIELD_VALIDATION_MAP,
  VoucherRedemptionData,
} from "../../DealerVoucher/dealer_dashboard/dashboard.utils";
import { CustomStyledTextField } from "../../DealerVoucher/dealer_dashboard/dashboard.styles";
import VoucherVerifyOTP from "./voucherVerifyOtp";
import VoucherCard from "./voucherCard";
import VoucherHistoryTable from "./voucherHistoryTable";
const moment = require("moment");
import DeleteIcon from "@mui/icons-material/Delete";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const RedeemVoucherModal = ({
  open,
  onModalClose,
  classes,
  voucherCode,
  onVoucherSearchChange,
  errors,
  onValidateVoucher,
  selectedVoucher,
  otpValue,
  handleOtpChange,
  handleOtpVerify,
  txnId,
  txnAttachment,
  handleFileChange,
  handleRemoveFile,
  onTxnIdChange,
  isVoucherRedeemed,
  voucher,
  initialSeconds,
  spanRef,
  showTimer,
  isLoading,
  fileInputRef
}) => {
  const modalDetails = {
    modalHeader: "Voucher Redemption",
    actionButtonLabel: "",
  };

  const handleClose = () => {
    onModalClose?.();
  };

  // not allowing new lines
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    onVoucherSearchChange?.(value);
  };

  return (
    <DialogComponent
      open={open}
      maxWidth={"md"}
      fullWidth={true}
      handleClose={handleClose}
      modalDetails={modalDetails}
      doNotRenderActionsLayer={true}
      customDialogTitleCssObj={classes.dialogTitle}
    >
      <Grid container style={{ marginTop: "5px" }}>
        <Grid item xs={12} sm={9}>
          <CustomStyledTextField
            fullWidth
            required
            name="voucherCode"
            disabled={!!selectedVoucher}
            inputProps={{
              maxLength: FORM_FIELD_VALIDATION_MAP.voucherCode.maxLength,
              onKeyDown: handleKeyDown,
            }}
            onChange={(e) => {
              if (selectedVoucher) return;
              handleChange(e);
            }}
            value={voucherCode}
            placeholder="ABCD1XYZ"
            label="Voucher Code"
            variant="outlined"
            error={!!errors?.voucherCode}
            helperText={String(errors?.voucherCode || "")}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            name="ValidateVoucher"
            className={
              !!selectedVoucher
                ? classes.validateVoucherDisabled
                : classes.validateVoucher
            }
            onClick={() => {
              onValidateVoucher?.();
            }}
            disabled={!!selectedVoucher}
          >
            Validate Voucher
          </Button>
        </Grid>
      </Grid>

      {voucher && voucher?.voucherCode && (
        <VoucherCard
          classes={classes}
          voucherCode={voucher?.voucherCode}
          points={voucher?.points}
          validity={moment.utc(voucher?.redemption_end_date).format("Do MMM YYYY")}
          redemptionStatus={!!voucher?.isRedeemed ? "Redeemed" : "Redeemable"}
          onRedeem={() => {}}
          isDisabled={!!voucher?.isRedeemed}
        />
      )}

      {selectedVoucher && voucher?.isRedeemed && (
        <div style={{ marginTop: "20px" }}>
          <VoucherHistoryTable
            rowData={[voucher]}
            showRoName={true}
            tableHeight="130px"
            showPagination={false}
          />
        </div>
      )}

      {voucher && voucher?.voucherCode && !voucher?.isRedeemed && (
        <Grid container style={{ marginTop: "25px" }}>
          <Grid
            item
            xs={12}
            sm={3}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className={classes.detailHeading}>
              Transaction Id
            </Typography>
          </Grid>
          <Grid item xs={11} sm={8}>
            <CustomStyledTextField
              fullWidth
              required
              name="TxnId"
              // disabled={!!selectedVoucher}
              inputProps={{
                maxLength: FORM_FIELD_VALIDATION_MAP.txnId.maxLength,
                onKeyDown: handleKeyDown,
              }}
              onChange={(e) => {
                // if (selectedVoucher) return;
                onTxnIdChange?.(e.target.value);
              }}
              value={txnId}
              placeholder="1122TXN234"
              label="Enter Transaction Id"
              variant="outlined"
              error={!!errors?.txnId || !!errors.txnAttachment}
              helperText={String(errors?.txnId || errors.txnAttachment || txnAttachment?.fileName || "")}
            />
          </Grid>
          <Grid item xs={1} sx={{ display: "flex", alignItems: "center" }}>
            <>
              <input
                accept=".jpg,.jpeg,.png,application/pdf"
                id="txn_attachment"
                name="txn_attachment"
                type="file"
                ref={fileInputRef} // Attach the ref here
                onChange={(e) => {
                  handleFileChange(e);
                }}
                style={{ display: "none" }}
              />
              {txnAttachment?.file ? (
                <IconButton
                  onClick={() => handleRemoveFile()}
                  aria-label="remove file"
                >
                  <DeleteIcon />
                </IconButton>
              ) : (
                <label htmlFor="txn_attachment">
                  <IconButton component="span">
                    <AttachFileIcon />
                  </IconButton>
                </label>
              )}
            </>
          </Grid>
        </Grid>
      )}

      {voucher && voucher?.voucherCode && !voucher?.isRedeemed && (
        <VoucherVerifyOTP
          classes={classes}
          otp={otpValue}
          handleOtpChange={handleOtpChange}
          errors={errors}
          handleVerify={handleOtpVerify}
          showTimer={showTimer}
          spanRef={spanRef}
          initialSeconds={initialSeconds}
        />
      )}
      <Backdrop open={!!isLoading || false}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </DialogComponent>
  );
};

export default RedeemVoucherModal;
