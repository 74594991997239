import React, { useEffect, useRef } from "react";
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
const moment = require("moment");

const useStyles = makeStyles({
  divider: {
    marginTop: 30,
  },
  consentSubTitle: {
    fontWeight: "bold",
    margin: "20px 0 8px",
  },
  centerAlignSubTitle: {
    fontWeight: "bold",
    margin: "20px 0 8px",
    textAlign: 'center',
    whiteSpace: "pre-wrap"
  },
  consentDesciption: {
    marginBottom: 12,
  },
});

const CustomerConsent = ({
  open,
  handleClose,
  consentAgree,
  data,
  isCustomerSchemeConsent,
}) => {
  const classes = useStyles();
  const descriptionElementRef = useRef(null);
  const slabVal =
    data.slabType === "volume" ? (
      `${data.slabLimit}L`
    ) : (
      <>
        <span>&#x20B9;</span>
        {data.slabLimit}
      </>
    );
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const dieselDhamakaConsent = () => {
    return (
      <DialogContent dividers={true}>
        <DialogContentText
          id={`scroll-dialog-description`}
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          <Box>
            <Box className={classes.divider}>
              The terms and conditions are applicable to users registered on{" "}
              {data.disCountType} Scheme valid from{" "}
              {moment.utc(data.validFrom).format("DD-MMM-YYYY hh:mm:ss A")} till{" "}
              {moment.utc(data.validTo).format("DD-MMM-YYYY hh:mm:ss A")}
            </Box>
            <Box className={classes.consentSubTitle}>A. Eligibility:</Box>
            <Box className={classes.consentDesciption}>
              The Terms and Conditions contained herein shall apply to any
              person “User” registering by providing vehicle number and mobile
              number for participating in the {data.disCountType} scheme of
              Bharat Petroleum Corporation Ltd. (“BPCL”) or making registration
              through a gateway service through BPCL's link/app /website i.e.{" "}
              <a href={data.website} target="_blank" rel="noopener noreferrer">
                {data.website}
              </a>{" "}
              Each User is therefore deemed to have read and accepted these
              Terms and Conditions.
            </Box>
            <Box className={classes.consentSubTitle}>B. Offer Construct:</Box>
            <Box className={classes.consentDesciption}>
              1. BPCL shall hand over the Customer details to a chosen Vendor/
              Service Provider who will deliver gifts to customer on all
              transactions cumulating of fuel over the slab limit of {slabVal}{" "}
              during the offer period per customer identified by vehicle number
              and mobile number
            </Box>
            <Box className={classes.consentDesciption}>
              2. The Customer shall not be eligible for any gift below the slab
              of fuel {slabVal}.
            </Box>
            <Box className={classes.consentSubTitle}>
              C. General Terms & Conditions
            </Box>
            <Box className={classes.consentDesciption}>
              1. The Offer is for the Customers of BPCL and participation in
              this Offer is purely on voluntary basis.
            </Box>
            <Box className={classes.consentDesciption}>
              2. Communications to the Customers of campaign end results, slab
              wise gifts shall be done via SMS.
            </Box>
            <Box className={classes.consentDesciption}>
              3. The customer agrees that BPCL may collect, store, and share
              their personal data with vendors for facilitating the gift
              distribution.
            </Box>
            <Box className={classes.consentDesciption}>
              4. The gift distribution shall be applicable only in India.
            </Box>
            <Box className={classes.consentDesciption}>
              5. Any delays in processing the gifts to the Customers within the
              stipulated timeline will be communicated via SMS on a best effort
              basis to the Customers prior to the deadline(s).
            </Box>
            <Box className={classes.consentDesciption}>
              6. In all matters relating to the Offer, the decision of BPCL
              shall be final and binding in all respects on the Customer.
            </Box>
            <Box className={classes.consentDesciption}>
              7. BPCL reserves the right, at any time, without prior notice and
              without assigning any reason whatsoever to the Customer, to
              add/alter/modify/change or vary all of these terms and conditions
              or to replace, wholly or in part, this Offer by another offer,
              whether similar to this Offer or not, or to extend or withdraw the
              Offer altogether.
            </Box>
            <Box className={classes.consentDesciption}>
              8. BPCL shall be entitled to revoke the Offer without any prior
              notice or intimation to the Customer(s). In such case the
              eligibility or the entitlement shall be appropriately adjusted at
              the sole discretion of BPCL.
            </Box>
            <Box className={classes.consentDesciption}>
              9. BPCL shall not be liable in any manner whatsoever if
              transaction fails due to technical issue
            </Box>
            <Box className={classes.consentDesciption}>
              10. Under no circumstances shall BPCL and/or the Vendor/Service
              Providers, its employees, directors, and its third-party agents
              involved in processing, delivering or managing the Gift
              distribution, be liable for any direct, indirect, incidental,
              special or consequential damages, or any damages whatsoever
            </Box>
            <Box className={classes.consentDesciption}>
              11. The Customer(s) agrees to Indemnify BPCL for or in respect of
              any loss, liability, cost or expenses incurred by BPCL on account
              of breach of any of the above terms and conditions on part of
              Customers(s) or breach of Applicable Laws on part of the
              Customer(s) or any third party liability incurred by BPCL which is
              attributable to the acts, deeds or things on part of the
              Customer(s) or any losses, costs or expenses incurred by the bank
              on account of any fraud or misrepresentation done by the Customer
              or the Customer gaming the system or the Offer
            </Box>
            <Box className={classes.consentSubTitle}>
              D. Governing Law and Jurisdiction:
            </Box>
            <Box className={classes.consentDesciption}>
              All disputes, if any, arising out of or in connection with or as a
              result of above Offer or otherwise relating hereto shall be
              subject to the exclusive jurisdiction of the competent courts /
              tribunals in Mumbai, irrespective of whether courts / tribunals in
              other areas have concurrent or similar jurisdiction.
            </Box>
          </Box>
        </DialogContentText>
      </DialogContent>
    );
  };

  const customerRegistrationConsent = () => {
    return (
      <DialogContent dividers={true}>
        <DialogContentText
          id={`scroll-dialog-description`}
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          <Box>
            <Box className={classes.divider}>
              The terms and conditions are applicable to users consenting to
              registered with BPCL
            </Box>
            <Box className={classes.consentSubTitle}>A. Eligibility:</Box>
            <Box className={classes.consentDesciption}>
              The Terms and Conditions contained herein shall apply to any
              person “User” registering by consenting to provide vehicle number
              and mobile number to Bharat Petroleum Corporation Ltd. (“BPCL”) or
              making registration through a gateway service through BPCL's
              link/app /website i.e.{" "}
              <a href={data.website} target="_blank" rel="noopener noreferrer">
                {data.website}
              </a>{" "}
              Each User is therefore deemed to have read and accepted these
              Terms and Conditions.
            </Box>
            <Box className={classes.consentSubTitle}>
              B. General Terms & Conditions
            </Box>
            <Box className={classes.consentDesciption}>
              1. The data of the Customers/Users of BPCL can be stored and
              shared as per requirement basis.
            </Box>
            <Box className={classes.consentDesciption}>
              2. Communications to the Customers can be made of latest
              campaigns, schemes and information via WhatsApp or SMS.
            </Box>
            <Box className={classes.consentDesciption}>
              3. The customer agrees that BPCL may collect, store, and share
              their personal data with vendors for facilitating the information
              communication and insights gathering.
            </Box>
            <Box className={classes.consentDesciption}>
              4. In all matters, the decision of BPCL shall be final and binding
              in all respects on the Customer.
            </Box>
            <Box className={classes.consentDesciption}>
              5. BPCL reserves the right, at any time, without prior notice and
              without assigning any reason whatsoever to the Customer, to
              add/alter/modify/change or vary all of these terms and conditions
              or to replace, wholly or in part, this Offer by another offer,
              whether similar to this Offer or not, or to extend or withdraw the
              Offer altogether.
            </Box>
            <Box className={classes.consentDesciption}>
              6. BPCL shall not be liable in any manner whatsoever if any
              transaction fails due to technical issue.
            </Box>
            <Box className={classes.consentDesciption}>
              7. Under no circumstances shall BPCL and/or the Vendor/Service
              Providers, its employees, directors, and its third-party agents
              involved in processing, delivering or managing the data, be liable
              for any direct, indirect, incidental, special or consequential
              damages, or any damages whatsoever
            </Box>
            <Box className={classes.consentDesciption}>
              8. The Customer(s) agrees to Indemnify BPCL for or in respect of
              any loss, liability, cost or expenses incurred by BPCL on account
              of breach of any of the above terms and conditions on part of
              Customers(s) or breach of Applicable Laws on part of the
              Customer(s) or any third party liability incurred by BPCL which is
              attributable to the acts, deeds or things on part of the
              Customer(s) or any losses , costs or expenses incurred by the bank
              on account of any fraud or misrepresentation done by the Customer
              or the Customer gaming the system or the Offer.
            </Box>
            <Box className={classes.consentSubTitle}>
              C. Governing Law and Jurisdiction:
            </Box>
            <Box className={classes.consentDesciption}>
              All disputes, if any, arising out of or in connection with or as a
              result of above Offer or otherwise relating hereto shall be
              subject to the exclusive jurisdiction of the competent courts /
              tribunals in Mumbai, irrespective of whether courts / tribunals in
              other areas have concurrent or similar jurisdiction.
            </Box>
          </Box>
        </DialogContentText>
      </DialogContent>
    );
  };

  const speedSchemeConsent = () => {
    return (
      <DialogContent dividers={true}>
        <DialogContentText
          id={`scroll-dialog-description`}
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          <Box className={classes.centerAlignSubTitle}>
            Bharat Petroleum Corporation Limited <br/>
            “{data.offer_abbr}”<br/>
            Terms and Conditions 
          </Box>
          <Box className={classes.centerAlignSubTitle}>
            Please read these terms and conditions carefully before registering to participate in 
            “{data.offer_abbr}” (referred to as "Offer"). By proceeding with your registration and 
            participation in this campaign, you hereby acknowledge, accept and thereby agree on an irrevocable 
            basis to be legally bound by these terms and conditions.<br/>
            <br/>
          </Box>
          <Box className={classes.consentDesciption}>
            1. This campaign offer is open to Indian Citizens living in India only who have attained the age of 18 years 
            or above (Participants). Entries by person below age of 18 years shall be disqualified. 
          </Box>
          <Box className={classes.consentDesciption}>
            2. Employees of the organizer i.e. Bharat Petroleum Corporation Ltd. (BPCL), BPCL Dealers / Distributors / Channel partners / employees of Advertising Agencies of BPCL, Service providers/Event managers etc. associated with this offer and their families, are not eligible for participation. 
          </Box>
          <Box className={classes.consentDesciption}>
            3. Offer commences on {moment.utc(data.validFrom).format("Do MMMM\'YY")} and closes on {moment.utc(data.validTo).format("Do MMMM\'YY")} at midnight (referred to as "Offer Period").  
          </Box>
          <Box className={classes.consentDesciption}>
            4. Entry to this offer shall be treated as void wherever (including any State or Union Territory) such offers are prohibited under local laws. 
          </Box>
          <Box className={classes.consentDesciption}>
            5. The offer is available at select BPCL (Bharat Petroleum Corporation Ltd) Retail Outlets in select geographies. The list of Retail Outlets where the offer is available is displayed on  <a href={data.website} target="_blank" rel="noopener noreferrer">
                {data.website} </a> &gt; Bharat Petroleum for &gt; Your Corner &gt; contest corner, at the end of this document. BPCL reserves right to add or remove retail outlet at its discretion. 
          </Box>
          <Box className={classes.consentDesciption}>
            6. Participation in this offer is deemed acceptance of these Terms and Conditions. 
          </Box>
          <Box className={classes.consentDesciption}>
            7. By entering this offer, the participant agrees that the information entered in the BPCL’s automation system and the mobile numbers from which the entries are received may be used by BPCL for research about this offer or communicate future promotions and other messages to the participants of this offer. By participating in this offer, the participant confirms and waives off the applicability of rules and regulations of National Do Not Call Registry.  
          </Box>
          <Box className={classes.consentDesciption}>
            8. Customers fueling Speed for an amount of Rs 300 or more at the participating retail outlets will be eligible to participate in the offer and stand a chance to win prizes in the offer. There are two customer segments created for the offer based on Speed fueling amount, viz. customers with Speed fueling of Rs. 300 to Rs. 1999.99 (Categorized as 2W segment for the purpose of this offer) and Customer with Speed fueling of Rs. 2000 and above (Categorized as 4W segment for the purpose of this offer). 
          </Box>
          <Box className={classes.consentDesciption}>
            9. Customers need to provide their mobile number to the fuel attendant at the BPCL retail outlets to participate in the offer after fueling Speed as per eligibility criteria given in above point no 7. Once fuel attendant enters the mobile number in BPCL’s automation system, an SMS message from the automation system will be triggered ONLY to customers who meet the criteria as mentioned in point no 7. The SMS text message will contain a link to fill up the customer’s name. Once a customer clicks on the link and provide name and submits after accepting terms and conditions, he/she will be registered in the offer. 
          </Box>
          <Box className={classes.consentDesciption}>
            10. There would be a weekly draw where customers will be chosen thru randomizer (except for states where lucky draw is not allowed as per local laws) and will win fuel as prize. Prize in terms of Fuel (Speed) for an amount of Rs. 500 will be given to lucky winners in a weekly draw from amongst segment of customers with Speed fueling slab of Rs. 300 to Rs. 1999.99 and with valid entries thru automation system of BPCL. Similarly, Prize in terms of Fuel (Speed) for an amount of Rs. 2000 will be given to lucky winners in weekly draw from amongst segment of customers with Speed fueling slab of Rs. 2000 & above and with valid entries thru automation system of BPCL. A total of 500 weekly lucky winners from the segment of customers with Speed fueling slab of Rs. 300 to Rs. 1999.99 and 150 weekly winners from the segment of customers with Speed fueling slab of Rs. 2000 & above (650 in total from both segments per week) will be selected through an electronic randomizer. 
          </Box>
          <Box className={classes.consentDesciption}>
            11. For weekly lucky winners, winners from the segment of customers with Speed fueling slab of Rs. 300 to Rs. 1999.99 will be eligible for a BPCL Fuel E- voucher of ₹500 and the segment of customers with Speed fueling slab of Rs. 2000 & above will be eligible for BPCL Fuel E- voucher of ₹2000. 
          </Box>
          <Box className={classes.consentDesciption}>
            12. Six number of lucky draws will be held after every week starting 1st June’24. 1st lucky draw will be held on 10th June for 1st to 7th June’24 eligible entries. Likewise, for every week, draws will be held every Monday 17th, 24th June etc.  
          </Box>
          <Box className={classes.consentDesciption}>
            13. In Mega Draw, there would be total 50 winners from the segment of customers with Speed fueling slab of Rs. 300 to Rs. 1999.99 who will be eligible for BPCL Fuel E- voucher of ₹10000 value and 15 winners from the segment of customers with Speed fueling slab of Rs. 2000 who will be eligible for BPCL Fuel E- voucher of ₹40000. 
          </Box>
          <Box className={classes.consentDesciption}>
            14. The fuel e-voucher will be restricted to the winning amount mentioned in point no 13 under the offer “{data.offer_abbr}”. 
          </Box>
          <Box className={classes.consentDesciption}>
            15. Winning customer can utilize the amount credited in the SmartDrive beyond one year as per the terms and conditions of HelloBPCL’s SmartDrive wallet. 
          </Box>
          <Box className={classes.consentDesciption}>
            16. Results of the weekly draws as well as Mega Draw would be published on the BPCL website. 
          </Box>
          <Box className={classes.consentDesciption}>
            17. The winners will be credited with BPCL Fuel E- voucher amount (equivalent to his winning amount) on HelloBPCL’s SmartDrive wallet. Customers need to download HelloBPCL app (App download available in Play Store and App Store) and login to SmartDrive option in HelloBPCL to redeem the amount credited in their SmartDrive wallet and visit the BPCL retail outlet from where Speed is purchased under the offer. 
          </Box>
          <Box className={classes.consentDesciption}>
            18. Winning customers would be intimated through SMS message which would contain link to download HelloBPCL app. 
          </Box>
          <Box className={classes.consentDesciption}>
            19. Winning customer is required to download HelloBPCL and login to Smart Drive within 6 months of receipt of intimation to him/her. If winner customer does not download HelloBPCL and login to Smart Drive within six months of intimation to him/her, the wining amount will become invalid. 
          </Box>
          <Box className={classes.consentDesciption}>
            20. The applicable tax for prize(s) if any, will be borne solely by the winning participant. 
          </Box>
          <Box className={classes.consentDesciption}>
            21. The offer is not transferable, i.e. offer is valid only for the mobile number through which customer had participated in the offer. 
          </Box>
          <Box className={classes.consentDesciption}>
            22. Reward/Loyalty points would not be accrued on the amount credited to customer in Smart Drive wallet under this offer. 
          </Box>
          <Box className={classes.consentDesciption}>
            23. Detailed terms and conditions are also available on BPCL website – <a href={data.website} target="_blank" rel="noopener noreferrer">
                {data.website} </a>  &gt; Bharat Petroleum for &gt; your Corner &gt; contest corner. 
          </Box>
          <Box className={classes.consentDesciption}>
            24. BPCL also reserves the right to amend/modify the terms and conditions or any part thereof at its sole discretion without prior notice. Any changes will be displayed on BPCL web Site. 
          </Box>
          <Box className={classes.consentDesciption}>
            25. All rights, title and interest, including but not limited to the intellectual property rights, in the campaign material(s) and in any and all responses received shall vest with BPCL.  
          </Box>
          <Box className={classes.consentDesciption}>
            26. Participation in the Offer is voluntary. Participation in this Offer constitutes acceptance of these terms and conditions. If a Participant is dissatisfied with the Offer or the Offer rules and/or any requirements of the Offer, or with these terms and conditions, his/her sole and exclusive remedy is to not participate in the Offer. 
          </Box>
          <Box className={classes.consentDesciption}>
            27. The Participant/s undertake and irrevocably and unconditionally permit Organizers to cover the Campaign through various media/platforms inter alia newspapers, radio, television, news channels, internet, point of sale materials, etc. Participants shall not raise any objection, protest or demur to such coverage and their use of name, voice, likeness, image, illustrations etc. by the Organizers in this regard. 
          </Box>
          <Box className={classes.consentDesciption}>
            28. The product (Speed) is available at BPCL retail outlets, without this offer also. 
          </Box>
          <Box className={classes.consentDesciption}>
            29. Unenforceability or invalidity of one or more clauses in these Terms and Conditions shall not have an effect on any other clause in these Terms and Conditions and the remaining clauses shall be enforceable as per law. 
          </Box>
          <Box className={classes.consentDesciption}>
            30. BPCL shall under no circumstances be liable, whether jointly or severally, for any loss or damage suffered or sustained (including but not limited to consequential loss), including for personal injury or property damage suffered or sustained, as a result of acceptance of campaign offer.  
          </Box>
          <Box className={classes.consentDesciption}>
            31. By entering the campaign, the Participant hereby releases and agrees to indemnify the Organizers from and against all liability, cost, loss or expenses arising out of acceptance of the free offer or participation in the Campaign including (but not limited to) personal injury and damage to property and whether direct, indirect, consequential, foreseeable, due to some negligent act or omission, or otherwise as well as any legal expenditure.  
          </Box>
          <Box className={classes.consentDesciption}>
            32. Dispute: The courts of competent jurisdiction at Mumbai shall have exclusive jurisdiction to determine any and all disputes arising out of, or in connection with, the Offer. Participation in the Offer is unauthorized in any jurisdiction that does not give effect to all provisions of these terms and conditions, including (without limitation) this clause. Further, all issues and questions concerning the construction, validity, interpretation and enforceability of these Terms & Conditions, or the rights and obligations of the Participants and/or the Organizers with respect to this Contest shall be governed and construed in accordance with the laws of the Republic of India. 
          </Box>
          <Box className={classes.consentDesciption}>
            33. BPCL indemnifies itself and/or any of its officials and representatives from any cost, damage, liability or other consequence of performance of their products offered as promotional prizes, under any applicable law. 
          </Box>
          <Box className={classes.consentDesciption}>
            34. For more details, customer can contact on z_rhqri_brand@bharatpetroleum.in 
          </Box>
        </DialogContentText>
      </DialogContent>
    );
  };

  const swiggySchemeConsent = () => {
    return (
      <DialogContent dividers={true}>
        <DialogContentText
          id={`scroll-dialog-description`}
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          <Box className={classes.centerAlignSubTitle}>
            Bharat Petroleum Corporation Limited <br />“{data.offer_abbr}”<br />
            Terms and Conditions
          </Box>
          <Box className={classes.centerAlignSubTitle}>
            Please read these terms and conditions carefully before registering
            to participate in “{data.offer_abbr}” (referred to as "Offer"). By
            proceeding with your registration and participation in this
            campaign, you hereby acknowledge, accept and thereby agree on an
            irrevocable basis to be legally bound by these terms and conditions.
            <br />
            <br />
          </Box>
          <Box className={classes.consentDesciption}>
            1. This campaign offer is open to Indian Citizens living in India
            only who have attained the age of 18 years or above (Participants).
            Entries by person below age of 18 years shall be disqualified.
          </Box>
          <Box className={classes.consentDesciption}>
            2. Employees of the organizer i.e. Bharat Petroleum Corporation Ltd.
            (BPCL), BPCL Dealers / Distributors / Channel partners / employees
            of Advertising Agencies of BPCL, Service providers/Event managers
            etc. associated with this offer and their families, are not eligible
            for participation.
          </Box>
          <Box className={classes.consentDesciption}>
            3. Offer commences on{" "}
            {moment.utc(data.validFrom).format("Do MMMM'YY")} and closes on{" "}
            {moment.utc(data.validTo).format("Do MMMM'YY")} at midnight
            (referred to as "Offer Period"). BPCL reserves the right to modify,
            extend, or cancel the campaign at any time without prior notice, for
            any reason, including but not limited to changes in regulatory
            requirements or unforeseen events.
          </Box>
          <Box className={classes.consentDesciption}>
            4. Entry to this offer shall be treated as void wherever (including
            any State or Union Territory) such offers are prohibited under local
            laws.
          </Box>
          <Box className={classes.consentDesciption}>
            5. The offer is available at select BPCL (Bharat Petroleum
            Corporation Ltd) Retail Outlets in select geographies. The list of
            Retail Outlets where the offer is available is displayed on{" "}
            <a href={data.website} target="_blank" rel="noopener noreferrer">
              {data.website}{" "}
            </a>{" "}
            &gt; Bharat Petroleum for &gt; Your Corner &gt; contest corner, at
            the end of this document. BPCL reserves right to add or remove
            retail outlet at its discretion.
          </Box>
          <Box className={classes.consentDesciption}>
            6. Participation in this offer is deemed acceptance of these Terms
            and Conditions.
          </Box>
          <Box className={classes.consentDesciption}>
            7. By entering this offer, the participant agrees that the
            information entered in the BPCL’s automation system and the mobile
            numbers from which the entries are received may be used by BPCL for
            research about this offer or communicate future promotions and other
            messages to the participants of this offer. By participating in this
            offer, the participant confirms and waives off the applicability of
            rules and regulations of National Do Not Call Registry.
          </Box>
          <Box className={classes.consentDesciption}>
            8. By participating in this offer, the participant confirms his
            express agreement to receive promotional messages pertaining to
            Speed on text, WhatsApp and other tech platforms.{" "}
          </Box>
          <Box className={classes.consentDesciption}>
            9. By participating in this offer, the participant confirms his
            express agreement to BPCL sharing the details, including phone
            numbers with the scheme partner M/s Swiggy, only for the purpose of
            research about this offer and/or communicate future promotions and
            other messages to the participants of this offer and the participant
            gives express consent for the same{" "}
          </Box>
          <Box className={classes.consentDesciption}>
            10. Disqualification: BPCL reserves the right to disqualify any
            entry or participant found to be violating the terms and conditions
            or engaging in any fraudulent, misleading, or inappropriate conduct.{" "}
          </Box>
          <Box className={classes.consentDesciption}>
            11. Customers fueling Speed for an amount of Rs 400 or more at one
            go at the participating retail outlets will be eligible to
            participate in the offer. BPCL reserves the right to change this
            minimum fueling criteria value during the course of the offer{" "}
          </Box>
          <Box className={classes.consentDesciption}>
            12. Customers need to provide their mobile number to the fuel
            attendant at the BPCL retail outlets to participate in the offer
            after fueling Speed as per eligibility criteria given in above point
            no 11. Once fuel attendant enters the mobile number in BPCL’s
            automation system, an SMS message from the automation system will be
            triggered ONLY to customers who meet the criteria as mentioned in
            point no 11. The SMS text message will contain a link to fill up the
            customer’s name, vehicle type etc. This link would be valid for only
            24 hours. Once a customer clicks on the link and provides the
            details and submits them after accepting terms and conditions,
            he/she will be registered in the offer. The customer will then
            receive Swiggy discount coupon of Rs. 100 over SMS on the same
            mobile number.{" "}
          </Box>
          <Box className={classes.consentDesciption}>
            13. The maximum number of Swiggy discount coupons that any mobile
            number will get in offer period is four with a cap of only one
            coupon per day.{" "}
          </Box>
          <Box className={classes.consentDesciption}>
            14. The Swiggy discount coupon of denomination of Rs. 100 will be
            given till 31.12.2024. for the balance part of the campaign, the
            denomination can be reduced by BPCL at its own discretion.{" "}
          </Box>
          <Box className={classes.consentDesciption}>
            15. Winning customer can utilize the coupon code received on Swiggy
            platform to order food, within the validity period. The validity
            period will be as per SMS. The minimum order value to avail of the
            Swiggy discount on Swiggy platform would be Rs. 250. The ordering of
            food on Swiggy platform will be subject to the terms and conditions
            of M/s Swiggy as per their platform.{" "}
          </Box>
          <Box className={classes.consentDesciption}>
            16. The onus of providing the correct mobile number before the
            transaction is complete, is with the customer. The offer will be
            only valid at outlets as notified by BPCL on website from time to
            time.{" "}
          </Box>
          <Box className={classes.consentDesciption}>
            17. At any outlet, the offer will be extended to the customer only
            if the transaction is carried out online. Neither BPCL nor its
            dealers or its agency shall be liable or responsible, if for any
            reason the customer is not able participate or receive SMS, due to
            any technical error or otherwise or towards the Discount coupon code
            message not being received by the customer{" "}
          </Box>
          <Box className={classes.consentDesciption}>
            18. The offer is not transferable, i.e. offer is valid only for the
            mobile number through which customer had participated in the offer.
            Any claim contrary to the same will not be considered{" "}
          </Box>
          <Box className={classes.consentDesciption}>
            19. Detailed terms and conditions are also available on BPCL website
            –{" "}
            <a href={data.website} target="_blank" rel="noopener noreferrer">
              {data.website}
            </a>{" "}
            &gt; Bharat Petroleum for &gt; your Corner &gt; contest corner.
          </Box>
          <Box className={classes.consentDesciption}>
            20. BPCL also reserves the right to amend/modify the terms and
            conditions or any part thereof at its sole discretion without prior
            notice. Any changes will be displayed on BPCL web Site.{" "}
          </Box>
          <Box className={classes.consentDesciption}>
            21. All rights, title and interest, including but not limited to the
            intellectual property rights, in the campaign material(s) and in any
            and all responses received shall vest with BPCL.{" "}
          </Box>
          <Box className={classes.consentDesciption}>
            22. Publicity Rights: Winners may be required to participate in
            promotional activities such as photos, videos, and interviews
            related to the campaign. By participating, participants give consent
            to BPCL to use their images, names, and likeness in media without
            additional compensation{" "}
          </Box>
          <Box className={classes.consentDesciption}>
            23. Participation in the Offer is voluntary. Participation in this
            Offer constitutes acceptance of these terms and conditions. If a
            Participant is dissatisfied with the Offer or the Offer rules and/or
            any requirements of the Offer, or with these terms and conditions,
            his/her sole and exclusive remedy is to not participate in the
            Offer.
          </Box>
          <Box className={classes.consentDesciption}>
            24. The Participant/s undertake and irrevocably and unconditionally
            permit Organizers to cover the Campaign through various
            media/platforms inter alia newspapers, radio, television, news
            channels, internet, point of sale materials, etc. Participants shall
            not raise any objection, protest or demur to such coverage and their
            use of name, voice, likeness, image, illustrations etc. by the
            Organizers in this regard.{" "}
          </Box>
          <Box className={classes.consentDesciption}>
            25. The product (Speed) is available at BPCL retail outlets, without
            this offer also.{" "}
          </Box>
          <Box className={classes.consentDesciption}>
            26. Unenforceability or invalidity of one or more clauses in these
            Terms and Conditions shall not have an effect on any other clause in
            these Terms and Conditions and the remaining clauses shall be
            enforceable as per law.{" "}
          </Box>
          <Box className={classes.consentDesciption}>
            27. BPCL shall under no circumstances be liable, whether jointly or
            severally, for any loss or damage suffered or sustained (including
            but not limited to consequential loss), including for personal
            injury or property damage suffered or sustained, as a result of
            acceptance of campaign offer.{" "}
          </Box>
          <Box className={classes.consentDesciption}>
            28. By entering the campaign, the Participant hereby releases and
            agrees to indemnify the Organizers from and against all liability,
            cost, loss or expenses arising out of acceptance of the free offer
            or participation in the Campaign including (but not limited to)
            personal injury and damage to property and whether direct, indirect,
            consequential, foreseeable, due to some negligent act or omission,
            or otherwise as well as any legal expenditure.{" "}
          </Box>
          <Box className={classes.consentDesciption}>
            29. Dispute: The courts of competent jurisdiction at Mumbai shall
            have exclusive jurisdiction to determine any and all disputes
            arising out of, or in connection with, the Offer. Participation in
            the Offer is unauthorized in any jurisdiction that does not give
            effect to all provisions of these terms and conditions, including
            (without limitation) this clause. Further, all issues and questions
            concerning the construction, validity, interpretation and
            enforceability of these Terms & Conditions, or the rights and
            obligations of the Participants and/or the Organizers with respect
            to this Contest shall be governed and construed in accordance with
            the laws of the Republic of India.{" "}
          </Box>
          <Box className={classes.consentDesciption}>
            30. BPCL indemnifies itself and/or any of its officials and
            representatives from any cost, damage, liability or other
            consequences of the performance of their products offered as
            promotional prizes, under any applicable law.{" "}
          </Box>
          <Box className={classes.consentDesciption}>
            31. For more details, customer can contact on
            z_rhqri_brand@bharatpetroleum.in
          </Box>
          <Box className={classes.consentDesciption}>
            32. BPCL respects customers’ right to privacy in respect of any
            personal information provided to us. To see how BPCL collects and
            use customers personal information, please see BPCL’s Privacy Policy
            which is available at  <a href={'https://www.bharatpetroleum.in/images/files/bpcl%20-%20privacy%20policy%20-%20corporate%20website%20final.pdf'} target="_blank" rel="noopener noreferrer">
              {"BPCL’s Privacy Policy"}
            </a>{" "} By participating in this office the customers
            accept the terms of BPCL’s Privacy Policy. Customers have the right
            to withdraw their consent.{" "}
          </Box>
        </DialogContentText>
      </DialogContent>
    );
  };

  const gharHSDSchemeConsent = () => {
    return (
      <DialogContent dividers={true}>
        <DialogContentText
          id={`scroll-dialog-description`}
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          <Box>
            <Box className={classes.divider}>
              The terms and conditions are applicable to users registered on{" "}
              {data.disCountType} Scheme valid from{" "}
              {moment.utc(data.validFrom).format("DD-MMM-YYYY hh:mm:ss A")} till{" "}
              {moment.utc(data.validTo).format("DD-MMM-YYYY hh:mm:ss A")}
            </Box>
            <Box className={classes.consentSubTitle}>A. Eligibility:</Box>
            <Box className={classes.consentDesciption}>
              The Terms and Conditions contained herein shall apply to any
              person “User” registering by providing vehicle number and mobile
              number for participating in the {data.disCountType} scheme of
              Bharat Petroleum Corporation Ltd. (“BPCL”) or making registration
              through a gateway service through BPCL's link/app /website i.e.{" "}
              <a href={`https://${data.website}`} target="_blank" rel="noopener noreferrer">
                {data.website}
              </a>{" "}
              Each User is therefore deemed to have read and accepted these
              Terms and Conditions.
            </Box>
            <Box className={classes.consentSubTitle}>B. Offer Construct:</Box>
            <Box className={classes.consentDesciption}>
             1. Offering of Rs. 75 /- on fuelling 100 litres upto 200 litres and Rs. 150 /- on 
             fuelling 200 litres & above will be extended to the customers at the GHAR Retail 
             Outlets.   The offering will be in the form of fuel vouchers which can be redeemed 
             by the customers at GHAR Retail Outlets.
            </Box>
            <Box className={classes.consentDesciption}>
              2. The Customer shall not be eligible for any voucher below the slab
              of {slabVal} fuelling and redemption of voucher can be done on subsequent fuelling by the Customer.
            </Box>
            <Box className={classes.consentSubTitle}>
              C. General Terms & Conditions
            </Box>
            <Box className={classes.consentDesciption}>
              1. The Offer is for the Customers of BPCL and participation in
              this Offer is purely on voluntary basis.
            </Box>
            <Box className={classes.consentDesciption}>
             2. Communications to the Customers on becoming Eligible to Register for 
             the Scheme, Generation of Voucher and Redemption of Voucher will be done 
             through SMS.
            </Box>
            <Box className={classes.consentDesciption}>
              3. The customer agrees that BPCL may collect, store, and share their personal data 
              for keeping a track on the registration, vouchers getting generated and redemption 
              thereof.
            </Box>
            <Box className={classes.consentDesciption}>
              4. The voucher generation and redemption shall be applicable only in India.
            </Box>
            <Box className={classes.consentDesciption}>
              5. Any delays in  registration, generation of voucher and redemption of vouchers will be 
              communicated via SMS on a best effort basis to the Customers.
            </Box>
            <Box className={classes.consentDesciption}>
              6. In all matters relating to the Offer, the decision of BPCL
              shall be final and binding in all respects on the Customer.
            </Box>
            <Box className={classes.consentDesciption}>
              7. BPCL reserves the right, at any time, without prior notice and
              without assigning any reason whatsoever to the Customer, to
              add/alter/modify/change or vary all of these terms and conditions
              or to replace, wholly or in part, this Offer by another offer,
              whether similar to this Offer or not, or to extend or withdraw the
              Offer altogether.
            </Box>
            <Box className={classes.consentDesciption}>
              8. BPCL shall be entitled to revoke the Offer without any prior
              notice or intimation to the Customer(s). In such case the
              eligibility or the entitlement shall be appropriately adjusted at
              the sole discretion of BPCL.
            </Box>
            <Box className={classes.consentDesciption}>
              9. BPCL shall not be liable in any manner whatsoever if
              transaction fails due to technical issue
            </Box>
            <Box className={classes.consentDesciption}>
              10. Under no circumstances shall BPCL and/or the Vendor/Service Providers, its employees, 
              directors, and its third-party agents involved in processing, delivering or managing the 
              voucher generation & redemption, be liable for any direct, indirect, incidental, special or 
              consequential damages, or any damages whatsoever.
            </Box>
            <Box className={classes.consentDesciption}>
              11. The Customer(s) agrees to Indemnify BPCL for or in respect of
              any loss, liability, cost or expenses incurred by BPCL on account
              of breach of any of the above terms and conditions on part of
              Customers(s) or breach of Applicable Laws on part of the
              Customer(s) or any third party liability incurred by BPCL which is
              attributable to the acts, deeds or things on part of the
              Customer(s) or any losses, costs or expenses incurred by the bank
              on account of any fraud or misrepresentation done by the Customer
              or the Customer gaming the system or the Offer.
            </Box>
            <Box className={classes.consentSubTitle}>
              D. Governing Law and Jurisdiction:
            </Box>
            <Box className={classes.consentDesciption}>
              All disputes, if any, arising out of or in connection with or as a
              result of above Offer or otherwise relating hereto shall be
              subject to the exclusive jurisdiction of the competent courts /
              tribunals in Mumbai, irrespective of whether courts / tribunals in
              other areas have concurrent or similar jurisdiction.
            </Box>
          </Box>
        </DialogContentText>
      </DialogContent>
    );
  };

  const getConsentContent = () => {
    if (data.form_type === 'slogan') {
      return speedSchemeConsent();
    } else if (data.form_type === 'swiggy') {
      return swiggySchemeConsent();
    } else if (isCustomerSchemeConsent) {
      return gharHSDSchemeConsent();
    } else {
      return customerRegistrationConsent();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      aria-labelledby="scroll-dialog-title"
      aria-describedby={`scroll-dialog-description`}
    >
      <DialogTitle id="scroll-dialog-title">
        Terms and Conditions for User Registration
      </DialogTitle>
      {getConsentContent()}
      <DialogActions className={classes.consentAction}>
        <Button variant="outlined" color="error" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="success" onClick={consentAgree}>
          I Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerConsent;
