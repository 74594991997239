import { bpclProfiles } from "../config";
import auth from "../modules/auth";
const bpclProfileHost = bpclProfiles.host;


class authApi {
  static signup(userParams) {
    return fetchWithInterceptor(`${bpclProfileHost}/profiles/api/customer/register`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(userParams),
    })
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }

  static updateProfile(userParams) {
    return fetchWithInterceptor(`${bpclProfileHost}/profiles/api/customer/update`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify(userParams),
    })
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }

  static getStateByPincode(userParams) {
    return fetchWithInterceptor(`${bpclProfileHost}/pincode?pincode=${userParams}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }

  static getConsentDetails(userParams) {
    return fetchWithInterceptor(`${bpclProfileHost}/schemeInfo?offerid=${userParams}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }

  static getSmsDetails(userParams) {
    return fetchWithInterceptor(
      `${bpclProfileHost}/user_sms_details?unique_id=${userParams}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    )
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }

  static schemeRegister(userParams) {
    const formdata = new FormData();
    formdata.append("customer_name", userParams.customer_name);
    formdata.append("unique_id", userParams.unique_id);
    formdata.append("email_id", userParams.email_id);
    formdata.append("address", userParams.address);
    formdata.append("offer_id", userParams.offer_id);
    formdata.append("city", userParams.city);
    formdata.append("state", userParams.state);
    formdata.append("pin_code", userParams.pin_code);
    formdata.append("customer_consent", userParams.customer_consent);
    formdata.append("poi", userParams.poi);
    formdata.append("poi_type", userParams.poi_type);
    formdata.append("otp", userParams.otp);
    formdata.append("slogan", userParams.slogan);
    formdata.append("vehicle_type", userParams.vehicle_type);
    
    if (userParams.file) {
      formdata.append("file", userParams.file, userParams.fileName);
    }

    return fetchWithInterceptor(
      `${bpclProfileHost}/profiles/api/customer/schemeRegister`,
      {
        headers: {
          Accept: "application/json",
          // "Content-Type": "multipart/form-data",
        },
        method: "POST",
        body: formdata,
      }
    )
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }
}

export default authApi;
