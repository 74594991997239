import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import React from "react";

const SwiggyRegistrationForm = (props) => {
  const {
    classes,
    handleChange,
    customer,
    setCustomer,
    setOpen,
    handleClickOpen,
    error,
    schemeRegistrationFlag,
    profilePage,
  } = props;
  return (
    <>
      <Grid
        container
        columnSpacing={2}
        className={classes.registrationFormMain}
      >
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="standard-name"
            label="Customer Name"
            className={classes.textField}
            margin="normal"
            name="customerName"
            onChange={handleChange}
            value={customer.customerName}
            inputProps={{
              maxLength: 30,
            }}
            error={!!error.customerName}
            helperText={!!error.customerName ? error.customerName : ""}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="standard-number"
            label="Mobile Number"
            className={classes.textField}
            margin="normal"
            name="mobile"
            type="tel"
            inputProps={{
              maxLength: 10,
            }}
            onChange={handleChange}
            disabled={schemeRegistrationFlag || profilePage}
            value={customer.mobile}
            error={!!error.mobile}
            helperText={!!error.mobile ? error.mobile : ""}
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          spacing={2}
          className={classes.vehicleSection}
        >
          <Grid item xs={schemeRegistrationFlag || profilePage ? 6 : 5} md={6}>
            <TextField
              id="standard-vehicle-type"
              select
              //defaultValue={customer.preferredChannel}
              label="Vehicle Type"
              className={classes.textField}
              value={customer.vehicle_type}
              name="vehicle_type"
              disabled={profilePage} // Need to correct once backend changes done
              margin="normal"
              onChange={handleChange}
            >
              <MenuItem value={"TwoWheeler"}>Two Wheeler</MenuItem>
              <MenuItem value={"ThreeWheeler"}>Three Wheeler</MenuItem>
              <MenuItem value={"FourWheeler"}>Four Wheeler</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        {!profilePage && (
          <Grid item xs={12}>
            <Box className={classes.consent}>
              <FormControl error={!!error} component="fieldset">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={customer.consentAccepted}
                      onChange={() =>
                        customer.consentAccepted
                          ? setCustomer((prevFormData) => ({
                              ...prevFormData,
                              consentAccepted: !customer.consentAccepted,
                            }))
                          : setOpen(true)
                      }
                      name="consentAccepted"
                      color="primary"
                    />
                  }
                  label={
                    <>
                      I agree to the
                      <span
                        onClick={handleClickOpen}
                        className={classes.consentLink}
                        color="primary"
                      >
                        Terms and Conditions
                      </span>
                    </>
                  }
                />
                {error.consentAccepted && (
                  <Box className={classes.consentError}>
                    <FormHelperText>{error.consentAccepted}</FormHelperText>
                  </Box>
                )}
              </FormControl>
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default SwiggyRegistrationForm;
